// eslint-disable-next-line class-methods-use-this
import { getGeoIpDetails } from "sezzle-website/src/api/api";

declare const window: any;

export async function initApollo(appID: string) {
    const response = await getGeoIpDetails();
    const countryCode = response?.data?.country_iso_code;
    if (countryCode !== 'CA') {
        const n = Math.random().toString(36).substring(7);
        const o = document.createElement('script');

        o.src = `https://assets.apollo.io/micro/website-tracker/tracker.iife.js?nocache=${n}`;
        o.async = true;
        o.defer = true;

        o.onload = () => {
            if (window.trackingFunctions?.onLoad) {
                window.trackingFunctions.onLoad({
                    appId: appID,
                });
            } else {
                console.error('Apollo tracking functions are not available.');
            }
        };
        document.head.appendChild(o);
    }
}
