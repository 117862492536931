import * as FullStory from '@fullstory/browser';
import { getGeoIpDetails } from "sezzle-website/src/api/api";

/* eslint-disable */
const initFullStory = async () => {
    const response = await getGeoIpDetails();
    const countryCode = response?.data?.country_iso_code;
    if (countryCode !== 'CA') {
        FullStory.init({
            orgId: process.env.GATSBY_FULLSTORY_ORG_ID,
            recordOnlyThisIFrame: true,
            devMode:
                process.env.GATSBY_TARGET_ENV === 'development' ||
                process.env.GATSBY_TARGET_ENV === 'staging',
        });
    }
};

export default initFullStory;
