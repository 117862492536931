/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import '../../stylesheets/app.scss';
import { NavigationBarAndMenu, Footer } from '@library/shared-ui';
import initFullStory from './fullStory';
import { initZendeskWidget } from './zendesk';
import {
  getSessionStorage,
  setSessionStorage,
} from '../../utils/sessionManager';
import { ScreenSizeConfigurator } from '../../../../../library/shared-ui/components/ScreenSizeConfigurator';
import { initApollo } from './apollo';
import sezzleTheme from '../../templates/Page/themes';

const SearchUiSuggestions = React.lazy(() => import('../SearchUiSuggestions'));

const Layout = ({ children, data }) => {
  const showToPercentage = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  };

  const schema = React.useMemo(
    () =>
      children?.props?.children?.props?.children?.props?.data ||
      children?.props?.data ||
      data,
    [
      children?.props?.children?.props?.children?.props?.data,
      children?.props?.data,
      data,
    ]
  );
  const locales = React.useMemo(
    () =>
      children?.props?.children?.props?.children?.props ||
      children?.props ||
      data,
    [children?.props?.children?.props?.children?.props, children?.props, data]
  );

  const isBuilderPage = !!locales?.pageContext?.isBuilderPage;

  const navbarMenuSection = isBuilderPage
    ? schema?.contentfulNavigationBarAndMenu
    : schema?.contentfulPageTemplate?.navbarMenuSection;

  const footer = isBuilderPage
    ? schema?.contentfulFooter
    : schema?.contentfulPageTemplate?.footerSection;

  useEffect(() => {
    (async () => {
      const fullStory = getSessionStorage('fullStory');
      // verifies we are in prod - random initialization of fullstory if new session
      if (process.env.GATSBY_TARGET_ENV === '1' && fullStory !== 'true') {
        if (showToPercentage(10) === 0) await initFullStory();
        setSessionStorage('fullStory', 'true');
      }

      initZendeskWidget(process.env.GATSBY_ZENDESK_KEY);
      await initApollo(process.env.GATSBY_APOLLO_APP_ID);
    })()
  }, []);

  const cookieConsentData = React.useMemo(
    () => ({
      style: {
        background: sezzleTheme.backgroundColor,
        color: '#303030',
        fontFamily: sezzleTheme.buttonFont,
        paddingLeft: '8px',
        paddingTop: '2px',
        paddingBottom: '8px',
        borderRadius: '12px',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 4px 16px',
        zIndex: 9999999,
      },
      buttonStyle: {
        color: '#303030',
        background: sezzleTheme.backgroundColor,
        marginLeft: '0px',
        marginRight: '8px',
      },
      contentText: schema?.contentfulPageTemplate?.footerSection?.cookieConsent,
    }),
    [schema?.contentfulPageTemplate?.footerSection?.cookieConsent]
  );

  return (
    <ScreenSizeConfigurator>
      {!!navbarMenuSection && (
        <NavigationBarAndMenu
          {...navbarMenuSection}
          showSearchSuggestions
          searchSuggestions={<SearchUiSuggestions />}
          mobileButton={undefined}
        />
      )}
      <main>{children}</main>
      {!!footer && (
        <Footer
          {...footer}
          pageContext={locales?.pageContext || locales?.allContentfulLocale}
          cookieConsentData={cookieConsentData}
        />
      )}
    </ScreenSizeConfigurator>
  );
};

export default Layout;
