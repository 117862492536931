import React from 'react';
import { ThemeProvider } from 'styled-components';
import { MUIThemeProvider } from '@sezzle/sezzle-mui';
import Layout from './src/components/Layout';
import sezzleTheme from './src/templates/Page/themes';
import { GlobalWrapper } from './src/stylesheets/global';
import '@fontsource/open-sans';
import '@fontsource/comfortaa';
import '@fontsource/nunito-sans';
import { sendUTMInfo } from './utils';
import { initializeGTM } from '../../gtm';

export const wrapPageElement = ({ element, props }) => {
  sendUTMInfo();
  return props.pageContext.layout ? (
    <ThemeProvider theme={sezzleTheme}>
      <GlobalWrapper />
      <MUIThemeProvider>
        <Layout>{element}</Layout>
      </MUIThemeProvider>
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={sezzleTheme}>
      <GlobalWrapper />
      <MUIThemeProvider>
        <Layout>{element}</Layout>
      </MUIThemeProvider>
    </ThemeProvider>
  );
};

export const onClientEntry = async () => {
  await initializeGTM(process.env.GATSBY_GTM_ID);
};
